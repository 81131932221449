import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDocs,
  collection,
  orderBy,
  limit,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../firebase/firestore'; // Adjust the path as needed
import { useLocation } from 'react-router-dom';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

const userLevelss = [
  { id: 1, name: 'Rookie', icon: '/level1.png', tapBalanceRequired: 50000 },          // 50K tap balance
  { id: 2, name: 'Warrior', icon: '/level2.png', tapBalanceRequired: 500000 },         // 500K tap balance
  { id: 3, name: 'Legend', icon: '/level3.png', tapBalanceRequired: 1000000 },        // 1M tap balance
  { id: 4, name: 'Gladiator', icon: '/level4.png', tapBalanceRequired: 5000000 },        // 5M tap balance
  { id: 5, name: 'Master', icon: '/level5.png', tapBalanceRequired: 20000000 },       // 20M tap balance
  { id: 6, name: 'Titan', icon: '/level6.png', tapBalanceRequired: 50000000 },       // 50M tap balance
  { id: 7, name: 'King', icon: '/level7.png', tapBalanceRequired: 200000000 },      // 200M tap balance
  { id: 8, name: 'Devil', icon: '/level8.png', tapBalanceRequired: 500000000 },      // 500M tap balance
  { id: 9, name: 'Darkness', icon: '/level9.png', tapBalanceRequired: 1000000000 },     // 1B tap balance
  { id: 10, name: 'God', icon: '/level10.png', tapBalanceRequired: 10000000000 }  // 10B tap balance
];

const prTeam = [
  {
    title: 'Gym',
    level: [
      { level: 1, profit: 500, cost: 1500 },
      { level: 2, profit: 1500, cost: 3000 },
      { level: 3, profit: 2500, cost: 4500 },
      { level: 4, profit: 3500, cost: 6000 },
      { level: 5, profit: 4500, cost: 9000 },
      { level: 6, profit: 5500, cost: 12000 },
      { level: 7, profit: 16500, cost: 15000 },
      { level: 8, profit: 27500, cost: 18000 },
      { level: 9, profit: 58500, cost: 21000 },
      { level: 10, profit: 79500, cost: 30000 },
      { level: 11, profit: 10500, cost: 45000 },
      { level: 12, profit: 21500, cost: 75000 },
      { level: 13, profit: 42500, cost: 120000 },
      { level: 14, profit: 63500, cost: 180000 },
      { level: 15, profit: 74500, cost: 250000 },
    ],
    totalProfit: 0,
    icon: '/gym.png',
    description:
      'Develop the Gym practice needed to navigate volatile markets, making informed decisions that lead to long-term trading success.',
  },
  {
    title: 'Sparring',
    level: [
      { level: 1, profit: 950, cost: 5500 },
      { level: 2, profit: 1750, cost: 11000 },
      { level: 3, profit: 2550, cost: 22000 },
      { level: 4, profit: 3350, cost: 44000 },
      { level: 5, profit: 4150, cost: 55000 },
      { level: 6, profit: 4950, cost: 66000 },
      { level: 7, profit: 5750, cost: 88000 },
      { level: 8, profit: 6550, cost: 110000 },
      { level: 9, profit: 7350, cost: 132000 },
      { level: 10, profit: 18150, cost: 198000 },
      { level: 11, profit: 38950, cost: 264000 },
      { level: 12, profit: 69750, cost: 330000 },
      { level: 13, profit: 110550, cost: 550000 },
      { level: 14, profit: 211350, cost: 880000 },
      { level: 15, profit: 312150, cost: 1300000 },
    ],
    totalProfit: 0,
    icon: '/sparring.png',
    description:
      'Cultivate the discipline to stick to your trading strategy, minimizing risks and maximizing consistent returns over time.',
  },
  {
    title: 'Jiu Jitsu',
    level: [
      { level: 1, profit: 1700, cost: 7300 },
      { level: 2, profit: 2350, cost: 14600 },
      { level: 3, profit: 3000, cost: 29200 },
      { level: 4, profit: 3650, cost: 43800 },
      { level: 5, profit: 4300, cost: 58400 },
      { level: 6, profit: 4950, cost: 87600 },
      { level: 7, profit: 5600, cost: 116800 },
      { level: 8, profit: 6250, cost: 146000 },
      { level: 9, profit: 16900, cost: 175200 },
      { level: 10, profit: 37550, cost: 262800 },
      { level: 11, profit: 78200, cost: 350400 },
      { level: 12, profit: 108850, cost: 438000 },
      { level: 13, profit: 279500, cost: 730000 },
      { level: 14, profit: 310150, cost: 1168000 },
      { level: 15, profit: 510800, cost: 1750000 },
    ],
    totalProfit: 0,
    icon: '/jiujitsu.png',
    description:
      'Enhance your ability to adapt to market changes, ensuring your trading strategy remains effective in fluctuating conditions.',
  },
  {
    title: 'Taekwondo',
    level: [
      { level: 1, profit: 3500, cost: 18000 },
      { level: 2, profit: 5000, cost: 36000 },
      { level: 3, profit: 6500, cost: 72000 },
      { level: 4, profit: 8000, cost: 108000 },
      { level: 5, profit: 9500, cost: 144000 },
      { level: 6, profit: 11000, cost: 216000 },
      { level: 7, profit: 12500, cost: 288000 },
      { level: 8, profit: 54000, cost: 360000 },
      { level: 9, profit: 115500, cost: 432000 },
      { level: 10, profit: 317000, cost: 648000 },
      { level: 11, profit: 518500, cost: 864000 },
      { level: 12, profit: 720000, cost: 1080000 },
      { level: 13, profit: 821500, cost: 1800000 },
      { level: 14, profit: 1023000, cost: 2880000 },
      { level: 15, profit: 1224500, cost: 4000000 },
    ],
    totalProfit: 0,
    icon: '/Taekwondo.png',
    description:
      'Increase your market awareness, enabling you to anticipate trends and make informed trading decisions with confidence.',
  },
  {
    title: 'Кarate',
    level: [
      { level: 1, profit: 6700, cost: 27000 },
      { level: 2, profit: 8300, cost: 54000 },
      { level: 3, profit: 9900, cost: 108000 },
      { level: 4, profit: 11500, cost: 162000 },
      { level: 5, profit: 33100, cost: 216000 },
      { level: 6, profit: 84700, cost: 324000 },
      { level: 7, profit: 216300, cost: 432000 },
      { level: 8, profit: 517900, cost: 540000 },
      { level: 9, profit: 719500, cost: 648000 },
      { level: 10, profit: 1021100, cost: 972000 },
      { level: 11, profit: 1522700, cost: 1296000 },
      { level: 12, profit: 1724300, cost: 1620000 },
      { level: 13, profit: 2525900, cost: 2700000 },
      { level: 14, profit: 2727500, cost: 4320000 },
      { level: 15, profit: 3329100, cost: 6000000 },
    ],
    totalProfit: 0,
    icon: '/karate.png',
    description:
      'Master technical analysis to predict market movements and make data-driven trading decisions with greater precision.',
  },
  {
    title: 'Muay Thai',
    level: [
      { level: 1, profit: 8500, cost: 57000 },
      { level: 2, profit: 9700, cost: 114000 },
      { level: 3, profit: 10900, cost: 228000 },
      { level: 4, profit: 12100, cost: 342000 },
      { level: 5, profit: 13300, cost: 456000 },
      { level: 6, profit: 104500, cost: 684000 },
      { level: 7, profit: 315700, cost: 912000 },
      { level: 8, profit: 516900, cost: 1140000 },
      { level: 9, profit: 818100, cost: 1368000 },
      { level: 10, profit: 1319300, cost: 2052000 },
      { level: 11, profit: 2220500, cost: 2736000 },
      { level: 12, profit: 3121700, cost: 3420000 },
      { level: 13, profit: 3822900, cost: 5700000 },
      { level: 14, profit: 4024100, cost: 9120000 },
      { level: 15, profit: 4525300, cost: 12500000 },
    ],
    totalProfit: 0,
    icon: '/MuayThai.png',
    description:
      'Build a strong network within the crypto community, gaining insights and opportunities to enhance your trading performance.',
  },
  {
    title: 'MMA',
    level: [
      { level: 1, profit: 10800, cost: 73000 },
      { level: 2, profit: 12600, cost: 146000 },
      { level: 3, profit: 24400, cost: 292000 },
      { level: 4, profit: 36200, cost: 438000 },
      { level: 5, profit: 88000, cost: 584000 },
      { level: 6, profit: 159800, cost: 876000 },
      { level: 7, profit: 321600, cost: 1168000 },
      { level: 8, profit: 823400, cost: 1460000 },
      { level: 9, profit: 1225200, cost: 1752000 },
      { level: 10, profit: 2727000, cost: 2628000 },
      { level: 11, profit: 3528800, cost: 3504000 },
      { level: 12, profit: 5530600, cost: 4380000 },
      { level: 13, profit: 6332400, cost: 7300000 },
      { level: 14, profit: 6734200, cost: 11680000 },
      { level: 15, profit: 7036000, cost: 18000000 },
    ],
    totalProfit: 0,
    icon: '/MMA.png',
    description:
      'Build a strong network within the crypto community, gaining insights and opportunities to enhance your trading performance.',
  },

  {
    title: 'Kung Fu',
    level: [
      { level: 1, profit: 15700, cost: 73000 },
      { level: 2, profit: 23000, cost: 146000 },
      { level: 3, profit: 50300, cost: 292000 },
      { level: 4, profit: 87600, cost: 438000 },
      { level: 5, profit: 244900, cost: 584000 },
      { level: 6, profit: 352200, cost: 876000 },
      { level: 7, profit: 659500, cost: 1168000 },
      { level: 8, profit: 1066800, cost: 1460000 },
      { level: 9, profit: 1774100, cost: 1752000 },
      { level: 10, profit: 2981400, cost: 2628000 },
      { level: 11, profit: 3888700, cost: 3504000 },
      { level: 12, profit: 5296000, cost: 4380000 },
      { level: 13, profit: 6103300, cost: 7300000 },
      { level: 14, profit: 7110600, cost: 11680000 },
      { level: 15, profit: 8117900, cost: 18000000 },
    ],
    totalProfit: 0,
    icon: '/kungfu.png',
    description:
      'Build a strong network within the crypto community, gaining insights and opportunities to enhance your trading performance.',
  },
];


const marketTeam = [
  {
    title: 'VS. local gang',
    level: [
      { level: 1, profit: 18000, cost: 105000 },
      { level: 2, profit: 39500, cost: 210000 },
      { level: 3, profit: 61000, cost: 420000 },
      { level: 4, profit: 82500, cost: 840000 },
      { level: 5, profit: 104000, cost: 1200000 },
      { level: 6, profit: 125500, cost: 1500000 },
      { level: 7, profit: 147000, cost: 1800000 },
      { level: 8, profit: 268500, cost: 2200000 },
      { level: 9, profit: 590000, cost: 2700000 },
      { level: 10, profit: 2111500, cost: 3900000 },
      { level: 11, profit: 3233000, cost: 5000000 },
      { level: 12, profit: 5254500, cost: 6000000 },
      { level: 13, profit: 6276000, cost: 8000000 },
      { level: 14, profit: 8297500, cost: 10000000 },
      { level: 15, profit: 9319000, cost: 12000000 },
    ],
    totalProfit: 0,
    icon: '/localgang.png',
    description:
      'Guide your team in the crypto space, making strategic decisions that drive growth and secure exclusive market advantages.',
  },
  {
    title: 'VS. country boss',
    level: [
      { level: 1, profit: 55000, cost: 139000 },
      { level: 2, profit: 83000, cost: 278000 },
      { level: 3, profit: 111000, cost: 556000 },
      { level: 4, profit: 139000, cost: 1112000 },
      { level: 5, profit: 367000, cost: 1600000 },
      { level: 6, profit: 595000, cost: 2200000 },
      { level: 7, profit: 723000, cost: 2800000 },
      { level: 8, profit: 851000, cost: 3500000 },
      { level: 9, profit: 979000, cost: 4500000 },
      { level: 10, profit: 3107000, cost: 6000000 },
      { level: 11, profit: 5235000, cost: 7500000 },
      { level: 12, profit: 5363000, cost: 10000000 },
      { level: 13, profit: 5491000, cost: 13000000 },
      { level: 14, profit: 6019000, cost: 16000000 },
      { level: 15, profit: 6747000, cost: 20000000 },
    ],
    totalProfit: 0,
    icon: '/country_boss.png',
    description:
      'Master communication to effectively negotiate crypto trades, ensuring clarity and success in high-stakes market transactions.',
  },
  {
    title: 'VS. hidden expert',
    level: [
      { level: 1, profit: 76900, cost: 263000 },
      { level: 2, profit: 150000, cost: 526000 },
      { level: 3, profit: 223100, cost: 1052000 },
      { level: 4, profit: 296200, cost: 2104000 },
      { level: 5, profit: 569300, cost: 3000000 },
      { level: 6, profit: 842400, cost: 4000000 },
      { level: 7, profit: 1515500, cost: 5000000 },
      { level: 8, profit: 3588600, cost: 6200000 },
      { level: 9, profit: 3661700, cost: 7800000 },
      { level: 10, profit: 3734800, cost: 10000000 },
      { level: 11, profit: 4807900, cost: 13000000 },
      { level: 12, profit: 5081000, cost: 16000000 },
      { level: 13, profit: 5574100, cost: 20000000 },
      { level: 14, profit: 6027200, cost: 24000000 },
      { level: 15, profit: 6100300, cost: 30000000 },
    ],
    totalProfit: 0,
    icon: '/hiddenexpert.png',
    description:
      'Sharpen your negotiation skills to secure favorable trading terms and maximize profits in competitive cryptocurrency markets.',
  },
  {
    title: 'VS. former champion',
    level: [
      { level: 1, profit: 83000, cost: 375000 },
      { level: 2, profit: 240000, cost: 750000 },
      { level: 3, profit: 397000, cost: 1500000 },
      { level: 4, profit: 554000, cost: 3000000 },
      { level: 5, profit: 711000, cost: 4500000 },
      { level: 6, profit: 1868000, cost: 6000000 },
      { level: 7, profit: 3025000, cost: 7500000 },
      { level: 8, profit: 3182000, cost: 9500000 },
      { level: 9, profit: 4339000, cost: 12000000 },
      { level: 10, profit: 5496000, cost: 15000000 },
      { level: 11, profit: 5653000, cost: 18000000 },
      { level: 12, profit: 6010000, cost: 23000000 },
      { level: 13, profit: 6067000, cost: 29000000 },
      { level: 14, profit: 7724000, cost: 35000000 },
      { level: 15, profit: 8281000, cost: 45000000 },
    ],
    totalProfit: 0,
    icon: '/former.png',
    description:
      'Optimize your portfolio by managing assets effectively, ensuring sustainable growth in the volatile cryptocurrency landscape.',
  },
  {
    title: 'VS. dark forces',
    level: [
      { level: 1, profit: 125000, cost: 518000 },
      { level: 2, profit: 370000, cost: 1036000 },
      { level: 3, profit: 615000, cost: 2072000 },
      { level: 4, profit: 860000, cost: 4144000 },
      { level: 5, profit: 1105000, cost: 6000000 },
      { level: 6, profit: 3350000, cost: 8000000 },
      { level: 7, profit: 3595000, cost: 10000000 },
      { level: 8, profit: 4840000, cost: 12000000 },
      { level: 9, profit: 5085000, cost: 15000000 },
      { level: 10, profit: 5330000, cost: 20000000 },
      { level: 11, profit: 6575000, cost: 25000000 },
      { level: 12, profit: 7820000, cost: 32000000 },
      { level: 13, profit: 8165000, cost: 40000000 },
      { level: 14, profit: 8610000, cost: 50000000 },
      { level: 15, profit: 9555000, cost: 65000000 },
    ],
    totalProfit: 0,
    icon: '/dark.png',
    description:
      'Develop strategies to mitigate risks, protecting your investments while navigating the unpredictable crypto market.',
  },
  {
    title: 'VS. gang boss',
    level: [
      { level: 1, profit: 230000, cost: 620000 },
      { level: 2, profit: 550000, cost: 1240000 },
      { level: 3, profit: 870000, cost: 2480000 },
      { level: 4, profit: 1190000, cost: 4960000 },
      { level: 5, profit: 1510000, cost: 7000000 },
      { level: 6, profit: 1830000, cost: 9500000 },
      { level: 7, profit: 2150000, cost: 12000000 },
      { level: 8, profit: 3470000, cost: 15000000 },
      { level: 9, profit: 4790000, cost: 20000000 },
      { level: 10, profit: 5110000, cost: 25000000 },
      { level: 11, profit: 5430000, cost: 32000000 },
      { level: 12, profit: 6750000, cost: 40000000 },
      { level: 13, profit: 7070000, cost: 50000000 },
      { level: 14, profit: 7390000, cost: 65000000 },
      { level: 15, profit: 7710000, cost: 80000000 },
    ],
    totalProfit: 0,
    icon: '/gangboss.png',
    description:
      'Craft and execute plans that capitalize on market opportunities, positioning your assets for long-term success.',
  },
  {
    title: 'VS. former fighting champion',
    level: [
      { level: 1, profit: 360000, cost: 777000 },
      { level: 2, profit: 620000, cost: 1554000 },
      { level: 3, profit: 880000, cost: 3108000 },
      { level: 4, profit: 1140000, cost: 6216000 },
      { level: 5, profit: 1400000, cost: 9000000 },
      { level: 6, profit: 2660000, cost: 12000000 },
      { level: 7, profit: 3920000, cost: 15000000 },
      { level: 8, profit: 4180000, cost: 20000000 },
      { level: 9, profit: 5440000, cost: 25000000 },
      { level: 10, profit: 5700000, cost: 35000000 },
      { level: 11, profit: 6960000, cost: 45000000 },
      { level: 12, profit: 7220000, cost: 60000000 },
      { level: 13, profit: 7480000, cost: 80000000 },
      { level: 14, profit: 7740000, cost: 100000000 },
      { level: 15, profit: 8000000, cost: 150000000 },
    ],
    totalProfit: 0,
    icon: '/ffchamp.png',
    description:
      'Craft and execute plans that capitalize on market opportunities, positioning your assets for long-term success.',
  },
  {
    title: 'VS. national championship champion',
    level: [
      { level: 1, profit: 579000, cost: 999000 },
      { level: 2, profit: 887000, cost: 1998000 },
      { level: 3, profit: 1195000, cost: 3996000 },
      { level: 4, profit: 1503000, cost: 7992000 },
      { level: 5, profit: 1811000, cost: 10000000 },
      { level: 6, profit: 2119000, cost: 14000000 },
      { level: 7, profit: 2427000, cost: 18000000 },
      { level: 8, profit: 2735000, cost: 23000000 },
      { level: 9, profit: 3043000, cost: 30000000 },
      { level: 10, profit: 3351000, cost: 40000000 },
      { level: 11, profit: 4659000, cost: 50000000 },
      { level: 12, profit: 5967000, cost: 65000000 },
      { level: 13, profit: 6275000, cost: 85000000 },
      { level: 14, profit: 6583000, cost: 100000000 },
      { level: 15, profit: 7891000, cost: 150000000 },
    ],
    totalProfit: 0,
    icon: '/champ.png',
    description:
      'Craft and execute plans that capitalize on market opportunities, positioning your assets for long-term success.',
  },
];





const specialCards = [
  {
    title: 'VS. Muay Thai Master',
    profit: 10,
    cost: '500000000',
    icon: '/hunter.webp',
    tagline: 'Withdrawal access',
    description:
      'This is a special card that gives you special access benefits to some of the wallet features on $Wukong FIGHTER',
    class: 'specials1',
  },
  {
    title: 'Early Access',
    profit: 5,
    cost: '100000000',
    icon: '/access.webp',
    tagline: 'Withdrawal access',
    description:
      'With this special card you will stand high airdrop qualification chances and be among early token holders.',
    class: 'specials2',
  },
  {
    title: 'Balance Booster',
    profit: 50,
    cost: '1000000000',
    icon: '/booster.webp',
    tagline: 'Get more tokens',
    description: 'Get special access to boost your total balance in the boosters section, never a dull moment!',
    class: 'specials3',
  },
  {
    title: 'Token Swap Access',
    profit: 5,
    cost: '200000000',
    icon: '/swap.webp',
    tagline: 'Swap tokens special',
    description: 'This special card gives you access to token swap and withdrawal features in your wallet section.',
    class: 'specials4',
  },
];

export const UserProvider = ({ children }) => {
  const [isGameOpened, setIsGameOpened] = useState(false);
  const [balance, setBalance] = useState(0);
  const [tapBalance, setTapBalance] = useState(0);
  const [level, setLevel] = useState({ id: 1, name: 'Rookie (Level 1)', imgUrl: '/level1.png' });
  const [tapValue, setTapValue] = useState({ level: 1, value: 1 });
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(true);
  const [energy, setEnergy] = useState(0);
  const [battery, setBattery] = useState({ level: 1, energy: 500 });
  const [initialized, setInitialized] = useState(false);
  const [refBonus, setRefBonus] = useState(0);
  const [manualTasks, setManualTasks] = useState([]);
  const [showBalance, setShowBalance] = useState(true);
  const [userManualTasks, setUserManualTasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [claimedMilestones, setClaimedMilestones] = useState([]);
  const [claimedReferralRewards, setClaimedReferralRewards] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [selectedExchange, setSelectedExchange] = useState({
    id: 'selectex',
    icon: '/exchange.svg',
    name: 'Select exchange',
  });
  const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
  const [tapGuru, setTapGuru] = useState(false);
  const [mainTap, setMainTap] = useState(true);
  const [freeGuru, setFreeGuru] = useState(3);
  const [time, setTime] = useState(22);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [lastTime, setLastTime] = useState(null);
  const [claimExchangePoint, setClaimExchangePoint] = useState(true);
  const [selectedCharacter, setSelectedCharacter] = useState({ name: '', avatar: '' });
  const [characterMenu, setCharacterMenu] = useState(false);
  const [fullName, setFullName] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [isAddressSaved, setIsAddressSaved] = useState(false); // State to track if address is saved
  const [coolDownTime, setCoolDownTime] = useState(0);
  const [tappingGuru, setTappingGuru] = useState(0);
  const location = useLocation();
  const [openInfoTwo, setOpenInfoTwo] = useState(true);
  const [openInfoThree, setOpenInfoThree] = useState(true);
  const [lastCheckIn, setLastCheckIn] = useState(null);
  const [checkInDays, setCheckInDays] = useState([]);
  const [error, setError] = useState(null);
  const [showStartOverModal, setShowStartOverModal] = useState(false);
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [userLevels, setUserLevels] = useState(prTeam.map(() => 0)); // Start at level 0
  const [userLevelsMarket, setUserLevelsMarket] = useState(marketTeam.map(() => 0)); // Start at level 0
  const [totalProfit, setTotalProfit] = useState([0, 0, 0, 0, 0, 0]);
  const [totalMarketProfit, setTotalMarketProfit] = useState([0, 0, 0, 0, 0, 0]);
  const [success, setSuccess] = useState(false);
  const [profitHour, setProfitHour] = useState(0);
  const [purchasedCards, setPurchasedCards] = useState([]);
  const [totalCardsProfits, setTotalCardsProfits] = useState(0);
  const [withdrawals, setWithdrawals] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [swaps, setSwaps] = useState([]);
  const [youtubeTasks, setYoutubeTasks] = useState([]);
  const [userYoutubeTasks, setUserYoutubeTasks] = useState([]);
  // KM
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [activeUserRank, setActiveUserRank] = useState(null);

  // FARM KM:
  const [miningPower, setMiningPower] = useState(0);
  const [miningTotal, setMiningTotal] = useState(0);

  const assets = [
    { symbol: '$Wukong', name: '$Wukong FIGHTER', balance: balance, icon: '/wukcoin.png', price: 0.0004348 },
    { symbol: 'USDT', name: 'Tether US', balance: 0, icon: '/tether.webp', price: 1 },
    { symbol: 'TON', name: 'Toncoin', balance: 0, icon: '/ton.png', price: 6.68 },
    { symbol: 'NOT', name: 'Notcoin', balance: 0, icon: '/notcoin.jpg', price: 0.01075 },
    { symbol: 'BNB', name: 'BNB', balance: 0, icon: '/bnb2.webp', price: 562.36 },
    { symbol: 'SOL', name: 'Solana', balance: 0, icon: '/solana.png', price: 143.34 },
  ];

  const spinnerLimit = 10;

  const [walletAssets, setWalletAssets] = useState(assets);

  const [spinLimit, setSpinLimit] = useState(spinnerLimit); // New state for spin limit

  // Live mining power update
  useEffect(() => {
    const interval = setInterval(() => {
      const newMiningTotal = miningTotal + miningPower / 3600; // Adds mining power every second
      setMiningTotal(newMiningTotal);
    }, 1000);

    return () => clearInterval(interval);
  }, [miningPower, miningTotal]);

  // Offline mining update
  useEffect(() => {
    const handleOfflineMining = async () => {
      if (!id) return;
      const userRef = doc(db, 'telegramUsers', id);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const lastActive = userData.lastActive?.toDate();
        const now = new Date();
        const timeDifference = (now - lastActive) / 1000; // Time in seconds

        if (timeDifference > 0) {
          const offlineMiningTotal = (miningPower * timeDifference) / 3600;
          const updatedMiningTotal = miningTotal + offlineMiningTotal;

          setMiningTotal(updatedMiningTotal);
          await updateDoc(userRef, {
            miningTotal: updatedMiningTotal,
            lastActive: now,
          });
        }
      }
    };

    handleOfflineMining();
  }, [id, miningPower, miningTotal]);

  const startTimer = useCallback(() => {
    setTime(22); // Set the timer to 22 seconds
    setTapGuru(true); // Activate tapGuru mode
    setMainTap(false); // Disable main tapping during tapGuru mode
    setIsTimerRunning(true); // Mark the timer as running
  
    // Set a timeout to stop tapGuru after 22 seconds
    const timeout = setTimeout(() => {
      setTapGuru(false); // Deactivate tapGuru mode
      setMainTap(true); // Re-enable normal tapping after tapGuru ends
      setIsTimerRunning(false); // Stop the timer
    }, 22000); // 22000ms = 22 seconds
  
    return () => clearTimeout(timeout); // Cleanup timeout if component unmounts
  }, []);
  

  const fetchData = async (userId) => {
    if (!userId) return;
    try {
      const userRef = doc(db, 'telegramUsers', userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setBalance(userData.balance);
        setTapBalance(userData.tapBalance);
        setTapValue(userData.tapValue);
        setClaimedMilestones(userData.claimedMilestones || []);
        setClaimedReferralRewards(userData.claimedReferralRewards || []);
        setSelectedExchange(userData.selectedExchange);
        setSelectedCharacter(userData.character);
        setLastCheckIn(userData.lastCheckIn?.toDate() || null);
        setCheckInDays(userData.checkInDays || []);
        const data = userDoc.data().history || {};
        setWithdrawals(data.withdrawals || []);
        setDeposits(data.deposits || []);
        setSwaps(data.swaps || []);
        setFreeGuru(userData.freeGuru);
        setProfitHour(userData.profitHour || 0);
        setUserYoutubeTasks(userData.youtubeTasks || []);
        setWalletAddress(userData.address);
        setShowBalance(userData.showBalance);
        setIsAddressSaved(userData.isAddressSaved);
        setWalletAssets(userData.walletAssets || assets);
        setPurchasedCards(userData.specialCards || []);
        setEnergy(userData.energy);
        // Calculate total profits
        const total = purchasedCards.reduce((acc, card) => acc + card.profit, 0);
        setTotalCardsProfits(total);
        setFullName(userData.fullName);
        const span = userDoc.data().spinLimit ?? 10;
        setSpinLimit(span);
        setBattery(userData.battery);
        setLevel(userData.level);
        setId(userData.userId);
        setRefBonus(userData.refBonus || 0);
        setCompletedTasks(userData.tasksCompleted || []);
        setUserManualTasks(userData.manualTasks || []);
        setReferrals(userData.referrals || []);
        // FARm KM
        setMiningPower(userData.miningPower);
        setMiningTotal(userData.miningTotal);

        await updateActiveTime(userRef);
      }

      const tasksQuerySnapshot = await getDocs(collection(db, 'tasks'));
      const tasksData = tasksQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTasks(tasksData);

      const manualTasksQuerySnapshot = await getDocs(collection(db, 'manualTasks'));
      const manualTasksData = manualTasksQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setManualTasks(manualTasksData);

      // Fetch youtubeTasks
      const youtubeTasksQuerySnapshot = await getDocs(collection(db, 'youtubeTasks'));
      const youtubeTasksData = youtubeTasksQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setYoutubeTasks(youtubeTasksData);

      // KM leaderboard start
      const leadersQuerySnapshot = await getDocs(
        query(collection(db, 'leaderBoard'), orderBy('profitHour', 'desc'), limit(150))
      );
      const leadersData = leadersQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setLeaderBoard(leadersData);

      // PROBLEM - Step 1: Query Firestore to find the first 500 users with a balance greater than the current user
      const usersAboveQuery = query(
        collection(db, 'telegramUsers'),
        where('balance', '>', balance),
        orderBy('balance', 'desc'),
        limit(150)
      );

      const querySnapshot = await getDocs(usersAboveQuery);

      // Step 2: If the user is found in the first 500, set the rank as the number of users with a greater balance + 1
      let activeUserRank;
      if (querySnapshot.size > 0) {
        activeUserRank = querySnapshot.size + 1; // Your user is in the top 500
      } else {
        activeUserRank = '150+'; // Your user is not in the top 500
      }

      setActiveUserRank(activeUserRank); // Set the active user rank
      // KM end

      // Fetch settings data
      const settingsDocRef = doc(db, 'settings', 'utvYL5L3Brbpl51TEmCmAprnZ6F3'); // Replace with your actual document ID
      const settingsDocSnap = await getDoc(settingsDocRef);

      if (settingsDocSnap.exists()) {
        const settingsData = settingsDocSnap.data();
        setCoolDownTime(settingsData.coolDownTime);
        setTappingGuru(settingsData.tappingGuru);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
    setLoading(false);
  };

  const sendUserData = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    let referrerId = queryParams.get('ref');
    if (referrerId) {
      referrerId = referrerId.replace(/\D/g, '');
    }

    if (telegramUser) {
      const { id: userId, username, first_name: firstName, last_name: lastName } = telegramUser;
      const finalUsername = username || `${firstName}_${userId}`;
      const fullNamed = `${firstName} ${lastName}`;

      try {
        const userRef = doc(db, 'telegramUsers', userId.toString());
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          fetchData(userId.toString());
          await updateEnergy(userRef, userDoc.data().battery.energy);
          await updateReferrals(userRef);
          setInitialized(true);
          return;
        }

        const userData = {
          userId: userId.toString(),
          username: finalUsername,
          firstName: firstName,
          lastName: lastName,
          fullName: fullNamed,
          totalBalance: 0,
          showBalance: true,
          profitHour: 0,
          spinLimit: 10,
          isAddressSaved: false,
          address: '',
          balance: 0,
          tapBalance: 0,
          lastActive: new Date(),
          character: { name: '', avatar: '/user.webp' },
          freeGuru: 3,
          tapValue: { level: 1, value: 1 },
          level: { id: 1, name: 'Rookie (Level 1)', imgUrl: '/level1.png' },
          selectedExchange: { id: 'selectex', icon: '/exchange.svg', name: 'Choose exchanges' },
          energy: 500,
          battery: { level: 1, energy: 500 },
          refereeId: referrerId || null,
          referrals: [],
          // FARM KM
          miningPower: 2500,
          miningTotal: 0,
        };

        await setDoc(userRef, userData);
        setEnergy(500);
        setFreeGuru(userData.freeGuru);
        setSelectedCharacter(userData.character);
        setFullName(fullNamed);
        // FARM KM
        setMiningPower(miningPower);
        setMiningTotal(userData.miningTotal);
        // FARKM KM END
        setCharacterMenu(true);
        setSelectedExchange({ id: 'selectex', name: 'Choose exchange', icon: '/exchange.svg' });
        setId(userId.toString());

        if (referrerId) {
          const referrerRef = doc(db, 'telegramUsers', referrerId);
          const referrerDoc = await getDoc(referrerRef);
          if (referrerDoc.exists()) {
            await updateDoc(referrerRef, {
              referrals: arrayUnion({
                userId: userId.toString(),
                username: finalUsername,
                balance: 0,
                level: { id: 1, name: 'Rookie (Level 1)', imgUrl: '/level1.png' },
              }),
            });
          }
        }
        setInitialized(true);
        fetchData(userId.toString());
      } catch (error) {
        console.error('Error saving user in Firestore:', error);
      }
    }
  };

  const updateEnergy = async (userRef, batteryValue) => {
    const savedEndTime = localStorage.getItem('endTime');
    const savedEnergy = localStorage.getItem('energy');
    const endTime = new Date(savedEndTime);
    const newTimeLeft = endTime - new Date();
    if (newTimeLeft < 0 && savedEnergy <= 0) {
      try {
        await updateDoc(userRef, { energy: batteryValue });
        setEnergy(batteryValue);
      } catch (error) {
        console.error('Error updating energy:', error);
      }
    }
  };

  const updateActiveTime = async (userRef) => {
    try {
      await updateDoc(userRef, {
        lastActive: new Date(),
      });
      console.log('Active Time Updated');
    } catch (error) {
      console.error('Error updating Active Time:', error);
    }
  };

  const updateSpins = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const lastDate = userData.slotTimeStamp.toDate(); // Convert Firestore timestamp to JS Date
      const formattedDates = lastDate.toISOString().split('T')[0]; // Get the date part in YYYY-MM-DD format
      const currentDate = new Date(); // Get the current date
      const formattedCurrentDates = currentDate.toISOString().split('T')[0]; // Get the date part in YYYY-MM-DD format

      if (formattedDates !== formattedCurrentDates && userData.spinLimit <= 0) {
        await updateDoc(userRef, {
          spinLimit: 10,
          slotTimeStamp: new Date(),
        });
        setSpinLimit(10);
      }
    }
  };

  const updateReferrals = async (userRef) => {
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data();
    const referrals = userData.referrals || [];

    const updatedReferrals = await Promise.all(
      referrals.map(async (referral) => {
        const referralRef = doc(db, 'telegramUsers', referral.userId);
        const referralDoc = await getDoc(referralRef);
        if (referralDoc.exists()) {
          const referralData = referralDoc.data();
          return {
            ...referral,
            balance: referralData.balance,
            level: referralData.level,
          };
        }
        return referral;
      })
    );

    await updateDoc(userRef, { referrals: updatedReferrals });

    const totalEarnings = updatedReferrals.reduce((acc, curr) => acc + curr.balance, 0);
    const refBonus = Math.floor(totalEarnings * 0.1);
    const totalBalance = `${balance}` + refBonus;
    try {
      await updateDoc(userRef, { refBonus, totalBalance, lastActive: new Date() });
    } catch (error) {
      console.error('Error updating referrer bonus:', error);
    }
  };

  const updateUserLevel = async (userId, newTapBalance) => {
    let newLevel = { id: 1, name: 'Rookie (Level 1)', imgUrl: '/level1.png' };

    if (newTapBalance >= 5000 && newTapBalance < 100000) {
      newLevel = { id: 2, name: 'Warrior (Level 2)', imgUrl: '/level2.png' };
    } else if (newTapBalance >= 100000 && newTapBalance < 1000000) {
      newLevel = { id: 3, name: 'Legend (Level 3)', imgUrl: '/level3.png' };
    } else if (newTapBalance >= 1000000 && newTapBalance < 5000000) {
      newLevel = { id: 4, name: 'Gladiator (Level 4)', imgUrl: '/level4.png' };
    } else if (newTapBalance >= 5000000 && newTapBalance < 25000000) {
      newLevel = { id: 5, name: 'Master (Level 5)', imgUrl: '/level5.png' };
    } else if (newTapBalance >= 25000000 && newTapBalance >= 100000000) {
      newLevel = { id: 6, name: 'Titan (Level 6)', imgUrl: '/level6.png' };
    } else if (newTapBalance >= 100000000 && newTapBalance >= 300000000) {
      newLevel = { id: 6, name: 'King (Level 7)', imgUrl: '/level7.png' };
    } else if (newTapBalance >= 300000000 && newTapBalance >= 1000000000) {
      newLevel = { id: 6, name: 'Devil (Level 8)', imgUrl: '/level8.png' };
    } else if (newTapBalance >= 1000000000 && newTapBalance >= 5000000000) {
      newLevel = { id: 6, name: 'Darkness (Level 9)', imgUrl: '/level9.png' };
    } else if (newTapBalance >= 10000000000) {
      newLevel = { id: 6, name: 'God (Level 10)', imgUrl: '/level10.png' };
    }

    if (newLevel.id !== level.id) {
      setLevel(newLevel);
      const userRef = doc(db, 'telegramUsers', userId);
      await updateDoc(userRef, { level: newLevel });
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userRef = doc(db, 'telegramUsers', id.toString());
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Handle prTeam
        if (userData.prTeam) {
          const updatedLevels = prTeam.map((team) => {
            const teamData = userData.prTeam.find((t) => t.title === team.title);
            return teamData ? teamData.level : 0;
          });

          const updatedProfits = prTeam.map((team) => {
            const teamData = userData.prTeam.find((t) => t.title === team.title);
            return teamData ? teamData.totalProfit : 0;
          });

          setUserLevels(updatedLevels);
          setTotalProfit(updatedProfits);
        }

        // Handle marketTeam
        if (userData.marketTeam) {
          const updatedLevelsMarket = marketTeam.map((market) => {
            const marketData = userData.marketTeam.find((t) => t.title === market.title);
            return marketData ? marketData.level : 0;
          });

          const updatedMarketProfits = marketTeam.map((market) => {
            const marketData = userData.marketTeam.find((t) => t.title === market.title);
            return marketData ? marketData.totalMarketProfit : 0;
          });

          setUserLevelsMarket(updatedLevelsMarket);
          setTotalMarketProfit(updatedMarketProfits);
        }
      } else {
        console.error('User document does not exist');
      }
    };

    fetchUserData();
  }, [id]);

  const checkAndUpdateFreeGuru = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const lastDate = userData.timeSta.toDate(); // Convert Firestore timestamp to JS Date
      const formattedDates = lastDate.toISOString().split('T')[0]; // Get the date part in YYYY-MM-DD format
      const currentDate = new Date(); // Get the current date
      const formattedCurrentDates = currentDate.toISOString().split('T')[0]; // Get the date part in YYYY-MM-DD format

      if (formattedDates !== formattedCurrentDates && userData.freeGuru <= 0) {
        await updateDoc(userRef, {
          freeGuru: 3,
          timeSta: new Date(),
        });
        setFreeGuru(3);
      }
    }
  };

  useEffect(() => {
    const rewards = document.getElementById('reelsActivities');
    const rewardsTwo = document.getElementById('reels2Activities');

    if (location.pathname === '/rewards' || location.pathname === '/checkin') {
      rewards.style.background = '#935EDB';
      rewards.style.color = '#fff';
      rewardsTwo.style.color = '#fff';
      rewards.style.height = '60px';
      rewards.style.marginTop = '4px';
      rewards.style.paddingLeft = '6px';
      rewards.style.paddingRight = '6px';
    } else {
      rewards.style.background = '';
      rewards.style.color = '';
      rewards.style.height = '';
      rewards.style.marginTop = '';
      rewardsTwo.style.color = '';
      rewards.style.paddingLeft = '';
      rewards.style.paddingRight = '';
    }
  }, [location.pathname]);

  useEffect(() => {
    // Fetch the remaining clicks from Firestore when the component mounts
    const fetchRemainingClicks = async () => {
      if (id) {
        const userRef = doc(db, 'telegramUsers', id.toString());
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFreeGuru(userData.freeGuru || 0);
        }
      }
    };

    fetchRemainingClicks();
  }, [id]);

  useEffect(() => {
    // Calculate the new balance by adding balance and refBonus
    const newBalance = balance + refBonus;

    // Find the current '$Wukong' token in walletAssets
    const maxToken = walletAssets.find((asset) => asset.symbol === '$Wukong');

    // Check if maxToken exists and if its balance is different from the newBalance
    if (maxToken && maxToken.balance !== newBalance) {
      // Update the balance for the 'LYYC' token
      setWalletAssets((prevAssets) =>
        prevAssets.map((asset) => (asset.symbol === '$Wukong' ? { ...asset, balance: newBalance } : asset))
      );
    }
  }, [balance, refBonus, walletAssets]);

  useEffect(() => {
    const checkLastCheckIn = async () => {
      if (!id) return;

      try {
        const userDocRef = doc(db, 'telegramUsers', id);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const now = new Date();

          const lastCheckInDate = userData.lastCheckIn?.toDate();

          if (lastCheckInDate) {
            const lastCheckInMidnight = new Date(lastCheckInDate);
            lastCheckInMidnight.setHours(0, 0, 0, 0);

            const todayMidnight = new Date(now);
            todayMidnight.setHours(0, 0, 0, 0);

            const daysSinceLastCheckIn = Math.floor((todayMidnight - lastCheckInMidnight) / (1000 * 60 * 60 * 24));

            if (daysSinceLastCheckIn === 1) {
              // Last check-in was yesterday, prompt user to claim today's bonus
              setShowClaimModal(true);
            } else if (daysSinceLastCheckIn > 1) {
              // User missed a day, show the start over modal
              setShowStartOverModal(true);
            }
          } else {
            // First time check-in, set the check-in modal to be shown
            setShowClaimModal(true);
          }
        }
      } catch (err) {
        console.error('Error during initial check-in:', err);
        setError('An error occurred while checking your last check-in.');
      }
    };

    checkLastCheckIn();
  }, [id, setCheckInDays, setError]);

  useEffect(() => {
    if (id) {
      checkAndUpdateFreeGuru();
    }
    if (selectedCharacter.name === '') {
      setCharacterMenu(true);
    } else {
      setCharacterMenu(false);
    }
    updateSpins();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    sendUserData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (id) {
      updateUserLevel(id, tapBalance);
    }
    // eslint-disable-next-line
  }, [tapBalance, id]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <UserContext.Provider
      value={{
        balance,
        specialCards,
        fullName,
        youtubeTasks,
        setYoutubeTasks,
        userYoutubeTasks,
        setUserYoutubeTasks,
        purchasedCards,
        withdrawals,
        setWithdrawals,
        deposits,
        setDeposits,
        swaps,
        setSwaps,
        walletAssets,
        setWalletAssets,
        setPurchasedCards,
        totalCardsProfits,
        setTotalCardsProfits,
        userLevelss,
        success,
        setSuccess,
        userLevels,
        setUserLevels,
        totalMarketProfit,
        setTotalMarketProfit,
        userLevelsMarket,
        setUserLevelsMarket,
        prTeam,
        marketTeam,
        totalProfit,
        setTotalProfit,
        profitHour,
        setProfitHour,
        showStartOverModal,
        setShowStartOverModal,
        showClaimModal,
        setShowClaimModal,
        spinLimit,
        setSpinLimit,
        lastCheckIn,
        setLastCheckIn,
        checkInDays,
        setCheckInDays,
        error,
        setError,
        showBalance,
        setShowBalance,
        openInfoTwo,
        setOpenInfoTwo,
        openInfoThree,
        setOpenInfoThree,
        setFullName,
        coolDownTime,
        setCoolDownTime,
        tappingGuru,
        setTappingGuru,
        lastTime,
        walletAddress,
        setWalletAddress,
        isAddressSaved,
        setIsAddressSaved,
        selectedCharacter,
        setSelectedCharacter,
        characterMenu,
        setCharacterMenu,
        setLastTime,
        claimExchangePoint,
        setClaimExchangePoint,
        battery,
        freeGuru,
        setFreeGuru,
        isTimerRunning,
        setIsTimerRunning,
        time,
        setTime,
        startTimer,
        setBattery,
        tapGuru,
        setTapGuru,
        mainTap,
        setMainTap,
        selectedExchange,
        setSelectedExchange,
        tapValue,
        setTapValue,
        tapBalance,
        setTapBalance,
        level,
        energy,
        setEnergy,
        setBalance,
        setLevel,
        loading,
        setLoading,
        id,
        setId,
        sendUserData,
        leaderBoard,
        setLeaderBoard,
        activeUserRank,
        setActiveUserRank,
        initialized,
        setInitialized,
        refBonus,
        setRefBonus,
        manualTasks,
        setManualTasks,
        userManualTasks,
        setUserManualTasks,
        tasks,
        setTasks,
        completedTasks,
        setCompletedTasks,
        claimedMilestones,
        setClaimedMilestones,
        referrals,
        claimedReferralRewards,
        setClaimedReferralRewards,
        isGameOpened,
        setIsGameOpened,
        // FARM KM
        miningPower,
        setMiningPower,
        miningTotal,
        setMiningTotal,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
