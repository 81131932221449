import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { PiSpinnerBallDuotone } from 'react-icons/pi';
import { PiNotebookFill } from 'react-icons/pi';
import { PiHandTapFill } from 'react-icons/pi';
// import { IoWalletSharp } from "react-icons/io5";
import { useUser } from '../context/userContext';

const Footer = () => {
  const location = useLocation();
  const { selectedExchange } = useUser();

  const footerLinks = [
    {
      title: 'Fight',
      link: '/',
      icon:
        selectedExchange.id === 'selectex' ? (
          <img id={selectedExchange.id} src="/fight.png" alt="selected" className="w-[40px]" />
        ) : (
          <img id={selectedExchange.id} src="/fight.png" alt="selected" className="w-[40px]" />
        ),
    },
    {
      title: 'Games',
      /*link: '/roulette',*/
      link: '/miniGames',
      icon: (
        <img
          src="/games.png"
          alt="mine"
          className={location.pathname === '/roulette' ? 'w-[40px]' : 'w-[40px]'}
        />
      ),
    },
    {
      title: 'EARN',
      link: '/mine',
      icon: (
        <img
          src="/earn.png"
          alt="mine"
          className={location.pathname === '/mine' ? 'w-[40px]' : 'w-[40px]'}
        />
      ),
    },
    {
      title: 'Activities',
      link: '/tasks',
      icon: (
        <img
          src="/tasks.png"
          alt="quest"
          className={location.pathname === '/tasks' ? 'w-[40px]' : 'w-[40px]'}
        />
      ),
    },
    {
      title: 'Wallet',
      link: '/wallet',
      icon: (
        <img
          src="/wallet.png"
          alt="wallet"
          className={location.pathname === '/wallet' ? 'w-[40px]' : 'w-[40px]'}
        />
      ),
    },
  ];

  return (
    <div className="w-full z-30 fixed bottom-4 left-0 right-0 mx-auto max-w-md flex items-center justify-between px-4 h-16 bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800 bg-opacity-80 backdrop-blur-lg rounded-full shadow-xl border border-gray-700">
      {footerLinks.map((footer, index) => (
        <NavLink
          id={`reels${footer.title}`}
          key={index}
          to={footer.link}
          className={`flex flex-col items-center justify-center w-1/4 h-full transition-transform duration-200 transform ${
            location.pathname === `${footer.link}` ? 'text-white scale-110' : 'text-gray-400 hover:scale-105'
          }`}
        >
          <div
            id={`reels2${footer.title}`}
            className={`flex flex-col items-center justify-center space-y-1 ${
              location.pathname === `${footer.link}` ? 'text-white' : 'text-gray-400'
            }`}
          >
            <span
              className={`text-xl transition-colors duration-200 ${
                location.pathname === `${footer.link}` ? 'text-accent' : 'text-gray-400'
              }`}
            >
              {footer.icon}
            </span>
            <span
              id="reels3"
              className={`text-xs font-medium ${
                location.pathname === `${footer.link}` ? 'text-white' : 'text-gray-400'
              }`}
            >
              {footer.title}
            </span>
          </div>
          {location.pathname === `${footer.link}` && (
            <span
              className="absolute bottom-2 w-1 h-1 bg-accent rounded-full"
              style={{ top: '10px', right: '25px' }}
            ></span>
          )}
        </NavLink>
      ))}
    </div>
  );
};

export default Footer;
