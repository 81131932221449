import React, { useEffect, useState, useRef } from 'react';
import { useUser } from '../context/userContext';
import { RiArrowRightSLine } from 'react-icons/ri';
import { RiSettings4Fill } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';

// modals
import Levels from '../Components/Levels';
import SettingsMenu from '../Components/SettingsMenu';
import Exchanges from '../Components/Exchanges';
import BalanceInfo from '../Components/BalanceInfo';

const userLevels = [
  { id: 1, name: 'Rookie', icon: '/level1.png', tapBalanceRequired: 50000 },          // 50K tap balance
  { id: 2, name: 'Warrior', icon: '/level2.png', tapBalanceRequired: 500000 },         // 500K tap balance
  { id: 3, name: 'Legend', icon: '/level3.png', tapBalanceRequired: 1000000 },        // 1M tap balance
  { id: 4, name: 'Gladiator', icon: '/level4.png', tapBalanceRequired: 5000000 },        // 5M tap balance
  { id: 5, name: 'Master', icon: '/level5.png', tapBalanceRequired: 20000000 },       // 20M tap balance
  { id: 6, name: 'Titan', icon: '/level6.png', tapBalanceRequired: 50000000 },       // 50M tap balance
  { id: 7, name: 'King', icon: '/level7.png', tapBalanceRequired: 200000000 },      // 200M tap balance
  { id: 8, name: 'Devil', icon: '/level8.png', tapBalanceRequired: 500000000 },      // 500M tap balance
  { id: 9, name: 'Darkness', icon: '/level9.png', tapBalanceRequired: 1000000000 },     // 1B tap balance
  { id: 10, name: 'God', icon: '/level10.png', tapBalanceRequired: 10000000000 }  // 10B tap balance
];

const MiniGames = () => {
  const { fullName, tapBalance, refBonus, balance, selectedCharacter, selectedExchange } = useUser();

  const [showExchange, setShowExchange] = useState(false);
  const [info, setInfo] = useState(false);
  const [showLevel, setShowLevel] = useState(false);
  const initialLevelIndex = userLevels.findIndex((level) => tapBalance < level.tapBalanceRequired);
  const currentLevelIndex = initialLevelIndex === -1 ? userLevels.length - 1 : initialLevelIndex;
  const displayedLevelIndex = currentLevelIndex;
  const currentLevel = userLevels[displayedLevelIndex];
  const [showSetting, setShowSetting] = useState(false);

  const formatNumberCliam = (num) => {
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, ' ');
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, ' ');
    } else {
      return (num / 1000000).toFixed(2).replace('.', '.') + ' M';
    }
  };
  const scrollRef = useRef(null);

  useEffect(() => {
    const handleTouchMove = (e) => {
      e.stopPropagation(); // Allow scroll within this element on touch devices
    };

    const scrollElement = scrollRef.current;

    if (scrollElement) {
      scrollElement.addEventListener('touchmove', handleTouchMove, { passive: true });
    }

    // Clean up the event listener on component unmount
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, []);

  return (
    <>
      <div className="overflow-y-auto" ref={scrollRef} style={{ marginTop: '-12px' }}>
        {/* HEADER START */}
        <div className="flex justify-between bg-[#1a1a1a] p-4 items-center bg-gray-800 bg-opacity-50 p-3 rounded-bl-[22px] rounded-br-[22px] shadow-lg">
          {/* LEFT */}
          <div>
            {/* User Info */}
            <div className="flex items-center space-x-3">
              <div className="relative flex items-center justify-center overflow-hidden">
                <img
                  src={selectedCharacter.avatar}
                  className="w-12 h-12 rounded-full border-4 border-yellow-400 shadow-lg"
                  alt={fullName || 'user'}
                />
                <span className="absolute bottom-0 right-0 bg-green-500 w-4 h-4 rounded-full border-2 border-white"></span>
              </div>
              <div>
                <h1 className="text-lg font-bold text-white">{fullName}</h1>
                <span className="text-sm text-yellow-400 font-semibold">Fighter</span>
              </div>
            </div>
            {/* User Level */}
            <div style={{ margin: '10px 0 0 0' }}>
              {/* Progress Bar */}
              <div className="w-full bg-gray-700 rounded-full h-6 shadow-inner overflow-hidden">
                <div
                  className="bg-gradient-to-r from-green-400 to-blue-500 h-full rounded-full"
                  style={{ width: `${(tapBalance / currentLevel.tapBalanceRequired) * 100}%` }}
                />
              </div>

              {/* Level Information */}
              <div
                className="flex items-center justify-between mt-4"
                style={{
                  marginTop: '-26px',
                  zIndex: 3,
                  position: 'relative',
                  transform: 'scale(0.7)',
                }}
              >
                <span
                  onClick={() => setShowLevel(true)}
                  className="text-white cursor-pointer font-bold text-xl flex items-center hover:underline"
                >
                  Level {currentLevel.id}: {currentLevel.name}
                  <RiArrowRightSLine size={20} className="ml-2 text-yellow-400" />
                </span>
                <span className="text-gray-400 text-sm">
                  ({currentLevel.id}/{userLevels.length})
                </span>
              </div>
            </div>
          </div>
          {/* RIGHT */}
          <div className="flex flex-col items-end">
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setShowExchange(true)}
                className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition shadow-lg"
              >
                <img
                  id={selectedExchange.id}
                  src={selectedExchange.icon}
                  alt={selectedExchange.name}
                  className="w-6 h-6"
                />
              </button>
              <button
                onClick={() => setShowSetting(true)}
                className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition shadow-lg"
              >
                <RiSettings4Fill size={24} className="text-white" />
              </button>
            </div>
            <div
              className="w-fit py-[2px] px-3 flex items-center space-x-1 justify-center border-[1px] border-[#707070] rounded-[25px]"
              style={{ marginTop: '18px' }}
            >
              <span className="w-[14px]">
                <img alt="engy" src="/loader.webp" className="w-full" />
              </span>
              <h1 className="text-[15px] font-bold">{formatNumberCliam(balance + refBonus)}</h1>
            </div>
          </div>
        </div>
        {/* HEADER END */}

        <h1 className="text-center text-[24px] mt-[18px]">Mini Games</h1>

        <div className="grid grid-cols-2 gap-4 p-4">
          <div className="bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-lg">
            <h1 className="text-white text-[18px] font-bold"> Auto Mining</h1>
            <p className="text-gray-400 text-[14px] mt-2">Run and make money offline</p>
            <NavLink
              to="/farm"
              className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-[16px] font-semibold rounded-lg mt-4 p-2 w-full transform hover:scale-105 transition duration-300"
              style={{
                width: '100%',
                display: 'block',
                textAlign: 'center',
              }}
            >
              Play
            </NavLink>
          </div>

          <div className="bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-lg">
            <h1 className="text-white text-[18px] font-bold">Super Mining</h1>
            <p className="text-gray-400 text-[14px] mt-2">Catch as much rewards</p>
            <NavLink
              to="/moonbix"
              className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-[16px] font-semibold rounded-lg mt-4 p-2 w-full transform hover:scale-105 transition duration-300"
              style={{
                width: '100%',
                display: 'block',
                textAlign: 'center',
              }}
            >
              Play
            </NavLink>
          </div>

          <div className="bg-gray-800 bg-opacity-50 p-4 rounded-lg shadow-lg">
            <h1 className="text-white text-[18px] font-bold">Spin</h1>
            <p className="text-gray-400 text-[14px] mt-2">Try your luck</p>
            <NavLink
              to="/roulette"
              className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-[16px] font-semibold rounded-lg mt-4 p-2 w-full transform hover:scale-105 transition duration-300"
              style={{
                width: '100%',
                display: 'block',
                textAlign: 'center',
              }}
            >
              Play
            </NavLink>
          </div>
 
        </div>
      </div>

      {/* MODALS */}
      <Levels showLevel={showLevel} setShowLevel={setShowLevel} />
      <Exchanges showExchange={showExchange} setShowExchange={setShowExchange} />
      <SettingsMenu showSetting={showSetting} setShowSetting={setShowSetting} />
      <BalanceInfo info={info} setInfo={setInfo} />
    </>
  );
};

export default MiniGames;
