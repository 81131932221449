//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCliBAfppdlBJ7hJs1zxO5glGojOuwvAyw",
  authDomain: "wukfinance-a8051.firebaseapp.com",
  projectId: "wukfinance-a8051",
  storageBucket: "wukfinance-a8051.appspot.com",
  messagingSenderId: "204413111974",
  appId:  "1:204413111974:web:8bfe5e733a75538410ae67",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
